import { StaticImage } from 'gatsby-plugin-image'

import icon4 from '~/assets/images/ebook/icons/beauty.svg?file'
import icon6 from '~/assets/images/ebook/icons/housing.svg?file'
import icon2 from '~/assets/images/ebook/icons/news-websites.svg?file'
import icon1 from '~/assets/images/ebook/icons/online-marketplace.svg?file'
import icon5 from '~/assets/images/ebook/icons/social-media.svg?file'
import icon3 from '~/assets/images/ebook/icons/travel-industry.svg?file'
import { cx } from '~/utils'

import * as containerStyles from './IconsTitleSectionEbook.module.scss'

interface DataInfo {
  title: string
  icon: string
}

const dataInfo: DataInfo[] = [
  {
    title: 'Online marketplaces',
    icon: icon1,
  },
  {
    title: 'News websites',
    icon: icon2,
  },
  {
    title: 'Travel industry',
    icon: icon3,
  },
  {
    title: 'Beauty, health and food',
    icon: icon4,
  },
  {
    title: 'Social media and entertainment',
    icon: icon5,
  },
  {
    title: 'Housing and hotels',
    icon: icon6,
  },
]

const ebookImageTitle =
  'Free Whitepaper: Top 25 Progressive Web Applications | Codica'

const IconsTitleSectionEbook = () => (
  <div className="container container-md">
    <div className="row">
      <div className="col-lg-7 col-md-12">
        <div className={cx('row', containerStyles.iconsTitleSectionEbook__row)}>
          {dataInfo.map(({ title, icon }) => (
            <div className="col-md-6 col-sm-6 col-6" key={title}>
              <div className={containerStyles.iconsTitleSectionEbook__card}>
                <div className={containerStyles.iconsTitleSectionEbook__icon}>
                  <img
                    src={icon}
                    alt="Benefits icons | Codica"
                    title="Benefits icons"
                    loading="lazy"
                    width="auto"
                    height="auto"
                  />
                </div>
                <div className={containerStyles.iconsTitleSectionEbook__title}>
                  {title}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="offset-lg-1 col-lg-4 col-md-12">
        <StaticImage
          src="../../../../assets/images/ebook/image-ebook-pwa-whitepaper.png"
          alt={ebookImageTitle}
          title={ebookImageTitle}
          width={196}
          height={330}
          placeholder="blurred"
          className={containerStyles.iconsTitleSectionEbook__image}
        />
      </div>
    </div>
  </div>
)

export default IconsTitleSectionEbook
