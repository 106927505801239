import { Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import HeadSection from '~/components/blocks/HeadSection'
import EbookForm from '~/components/forms/EbookForm'
import MainLayout from '~/components/layouts/MainLayout'
import LazyHydrate from '~/components/shared/LazyHydrate'
import ReCaptchaProvider from '~/components/shared/ReCaptchaProvider'
import { fileToImageLikeData } from '~/utils'
import IconsTitleSectionEbook from '~/views/EbookLandings/components/IconsTitleSectionEbook'
import InfoSectionLeadMagnets from '~/views/EbookLandings/components/InfoSectionLeadMagnets'
import PrimeSectionLeadMagnets from '~/views/EbookLandings/components/PrimeSectionLeadMagnets'
import usePwaFreeWhitepaperStaticQuery from '~/views/EbookLandings/PwaFreeWhitepaper/usePwaFreeWhitepaperStaticQuery'

import * as containerStyles from './PwaFreeWhitepaper.module.scss'

const PwaFreeWhitepaper = () => {
  const query = usePwaFreeWhitepaperStaticQuery()
  const imageEbook = getImage(fileToImageLikeData(query.imageEbook))

  return (
    <MainLayout isTransparent>
      <PrimeSectionLeadMagnets
        title="Free Whitepaper: Top 25 Progressive Web Applications"
        description="PWA implementation examples from world-known brands"
        maxWidthTitle="750px"
        maxWidthDescription="750px"
      />

      <InfoSectionLeadMagnets
        imageEbook={imageEbook}
        imageClass="pwaDevelopmentEbookWhitepaper"
        imageMeta="Free Whitepaper: Top 25 Progressive Web Applications | Codica"
        description={
          <>
            Quick, engaging, working offline, easy-to-install, progressive web
            apps have become a great cost-effective alternative to native apps.
            <span className="mb-4 d-block" key="keyOne" />
            By implementing
            <Link
              to="/services/progressive-web-apps-development/"
              className="mx5"
              key="pwaDescrLink"
            >
              progressive web app development services
            </Link>
            the world’s top businesses have managed to improve user retention,
            increase conversion up to 3x times, and all this - without having to
            spend a fortune.
            <span className="mb-4 d-block" key="keyTwo" />
            Want to know how the PWA technology can boost your business? We have
            prepared a free detailed analysis of the top 25 progressive web apps
            implemented by world-famous brands.
          </>
        }
        isButton
        buttonName="Download free whitepaper"
        buttonId="downloadFreeWhitePaperButton"
      />

      <section className={containerStyles.pwaWhitepaperEbook__isbgGrey}>
        <HeadSection
          position="center"
          title="What’s inside?"
          description="Learn from the experience of AliExpress, Twitter, Tinder, Pinterest, and other top brands. We have analyzed 25 best PWA implementation case studies, all classified by domain:"
          widthDescription="830px"
        />
        <IconsTitleSectionEbook />
        <div
          id="downloadPdf"
          className={containerStyles.pwaWhitepaperEbook__downloadEbook}
        >
          <LazyHydrate whenVisible>
            <ReCaptchaProvider>
              <EbookForm
                fileName="Top 25 Progressive Web Applications PWAs"
                urlPath="thank-you-top-25-progressive-web-application-pwa-examples"
                formTitle="Download free whitepaper and learn how your business can grow with a PWA"
                dataId="downloadWhitepaperButton"
              />
            </ReCaptchaProvider>
          </LazyHydrate>
        </div>
      </section>
    </MainLayout>
  )
}

export default PwaFreeWhitepaper
